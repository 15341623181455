<span
  class="relative inline-block px-2 py-1 font-semibold leading-tight sm:px-2"
>
  <span
    aria-hidden
    [ngClass]="[
      status === 'denied' || status === 'withdrawn' || status === 'terminated'
        ? 'bg-red-50 '
        : '',
      status === 'completed' || status === 'approved' ? 'bg-green-100 ' : '',
      status === 'initiated' ? 'bg-orange-300 ' : '',
      status === 'in_process' ? 'bg-blue-300 ' : '',
      status === 'pending' || status === 'withheld' ? 'bg-mango-paleBlue ' : ''
    ]"
    class="absolute inset-0 rounded-full opacity-50"
  ></span>
  <span
    [ngClass]="[
      status === 'denied' || status === 'withdrawn' || status === 'terminated'
        ? ' text-mango-red707'
        : '',
      status === 'completed' || status === 'approved'
        ? ' text-mango-pineGreen'
        : '',
      status === 'initiated' ? ' text-orange-900' : '',
      status === 'in_process' ? ' text-blue-700' : '',
      status === 'pending' || status === 'withheld' ? ' text-indigo-700' : ''
    ]"
    class="relative flex sm:text-[11px]"
  >
    {{ status | uppercase }}</span
  >
</span>
